import React from 'react'

/* --------------------------------- Assets --------------------------------- */
import logo from 'assets/images/logo.svg'

/* ------------------------------- Components ------------------------------- */
import Time from './time'
import Contact from './contact'
import Status from './status'
import Sunset from './sunset'

/* -------------------------------------------------------------------------- */
/*                              Header Component                              */
/* -------------------------------------------------------------------------- */
const Header = () => (
  <div className="header">
    <div className="header__logo">
      <img src={logo} alt="EPSI Logo" />
    </div>
    <div className="header__contact">
      <Contact />
    </div>
    <div className="header__status">
      <Status />
    </div>
    <div className="header__sunset">
      <Sunset />
    </div>
    <div className="header__time">
      <Time />
    </div>
  </div>
)

/* --------------------------------- Export --------------------------------- */
export default Header
