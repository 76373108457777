import React, { useEffect, useRef, useCallback } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'
import api from 'providers/api'
import { defaultRequest } from 'providers/request'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { setData } from 'features/weather/weatherSlice'

/* ------------------------------- Components ------------------------------- */
import Weather from './Weather'

/* -------------------------------------------------------------------------- */
/*                         Weather Container Component                        */
/* -------------------------------------------------------------------------- */
const WeatherContainer = () => {
  const data = useSelector((state) => state.weather.data)
  const dispatch = useDispatch()
  const intervalRef = useRef()

  /* -------------------------------- Callbacks ------------------------------- */
  const getWeatherData = useCallback(
    () =>
      defaultRequest({
        request: () => api.get(`${config.api.endpoints.weather_report}/24`),
        onSuccess: response => {
          dispatch(setData(response.data))
        },
      }),
    [dispatch, setData],
  )

  /* ------------------------------- Use effects ------------------------------ */
  useEffect(() => {
    getWeatherData()
    intervalRef.current = setInterval(getWeatherData, 5000)

    return () => clearInterval(intervalRef.current)
  }, [getWeatherData])

  /* --------------------------------- Render --------------------------------- */
  return <Weather {...data} />
}

export default WeatherContainer
