import React, { useState } from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useSelector } from 'react-redux'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'
import api from 'providers/api'
import { defaultRequest } from 'providers/request'

/* ------------------------------- Components ------------------------------- */
import Status from './Status'

/* -------------------------------------------------------------------------- */
/*                         Status Container Component                         */
/* -------------------------------------------------------------------------- */
const StatusContainer = () => {
  const isOpen = useSelector((state) => state.status.data.is_open)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (state) => defaultRequest({
    beforeRequest: () => setIsLoading(true),
    afterRequest: () => setIsLoading(false),
    request: () => api.put(config.api.endpoints.status, {
      data: {
        is_open: state,
      },
    }),
  })

  return <Status {...{ isOpen, isLoading }} onChange={handleChange} />
}

export default StatusContainer
