const env = process.env.REACT_APP_ENV
const url = process.env.REACT_APP_API_ADDRESS
const token = process.env.REACT_APP_API_TOKEN
const fullUrl = `${url}/api/`
const metarUrl = process.env.REACT_APP_METAR_API_ADDRESS

export default {
  env,
  api: {
    url,
    fullUrl,
    token,
    endpoints: {
      status: '/status',
      weather_report: '/weather-report',
      announcements: '/announcements',
      arrivals: '/arrivals',
      current_arrivals: '/current-arrivals',
    },
  },
  time: {
    sunsetInterval: 5 * 60 * 1000,
  },
  metar: {
    api: {
      url: metarUrl,
    },
    icao: 'EPLL',
    refreshInterval: 5 * 60 * 1000,
  },
  units: {
    multipliers: {
      mstokt: 1.9438,
    },
  },
  announcements: {
    new_flag_duration: 24, // hours
  },
}
