import React, { useEffect, useState, useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import metarParser from 'aewx-metar-parser'
import moment from 'moment'
import config from 'config'

/* ------------------------------- Components ------------------------------- */
import Metar from './Metar'

/* -------------------------------------------------------------------------- */
/*                          Metar Container Component                         */
/* -------------------------------------------------------------------------- */
const MetarContainer = () => {
  const [data, setData] = useState()
  const intervalRef = useRef()

  const getMetarData = () => {
    const today = moment().format('YYYY-MM-DD')
    fetch(
      `${config.metar.api.url}?date=${today}&extended=true&icao=${config.metar.icao}`,
    )
      .then((response) => response.text())
      .then((result) => {
        const metar = result.split('\n').at(-3)
        const metarObject = metarParser(metar)
        setData(metarObject)
      })
  }

  useEffect(() => {
    getMetarData()
    intervalRef.current = setInterval(
      getMetarData,
      config.metar.refreshInterval,
    )

    return () => clearInterval(intervalRef.current)
  }, [])

  if (!data) return null

  return <Metar {...data} />
}

export default MetarContainer
