import React from 'react'

/* ---------------------------------- Redux --------------------------------- */
import { Provider } from 'react-redux'
import store from 'store'

/* --------------------------------- Styles --------------------------------- */
import 'assets/scss/index.scss'

/* ------------------------------- Components ------------------------------- */
import DefaultView from 'components/views/DefaultView'
import Sockets from 'components/utils/sockets'

/* -------------------------------------------------------------------------- */
/*                            Application Component                           */
/* -------------------------------------------------------------------------- */
const Application = () => (
  <Provider store={store}>
    <Sockets />
    <DefaultView />
  </Provider>
)

export default Application
