import React from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useSelector } from 'react-redux'

/* ---------------------------------- Icons --------------------------------- */
import { faMoon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* -------------------------------------------------------------------------- */
/*                              Sunset Component                              */
/* -------------------------------------------------------------------------- */
const Sunset = () => {
  const sunset = useSelector((state) => state.status.sunset)

  return (
    <div className="header__sunset">
      <FontAwesomeIcon icon={faMoon} className="mr-1" /> {sunset}
    </div>
  )
}

export default Sunset
