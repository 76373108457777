import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'
import { round } from 'utils/functions'

/* ---------------------------------- Icons --------------------------------- */
import { faRaindrops as faRaindropsLight } from '@fortawesome/pro-light-svg-icons'
import { faRaindrops as faRaindropsSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* -------------------------------------------------------------------------- */
/*                             Pressure Component                             */
/* -------------------------------------------------------------------------- */
const Rain = ({ rain, is_raining }) => (
  <div className="card">
    <div className="card__header mb-1">
      <div className="card__title">Deszcz</div>
      <div className="card__info">ostatnie 24h</div>
    </div>
    <h2 className="text-right tc-primary">
      <FontAwesomeIcon
        icon={is_raining ? faRaindropsSolid : faRaindropsLight}
        className={cn('mr-2', {
          'tc-gray-1': !is_raining,
          'tc-primary-4': is_raining,
        })}
        beat={is_raining}
      />
      <span>{round(rain, 1)} mm</span>
    </h2>
  </div>
)

export default Rain
