import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  isOnline: false,
  sunset: '00:00',
}

export const statusSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setIsOpen: (state, action) => {
      state.data.is_open = action.payload
    },
    setIsOnline: (state, action) => {
      state.isOnline = action.payload
    },
    setSunset: (state, action) => {
      state.sunset = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setData, setIsOpen, setIsOnline, setSunset,
} = statusSlice.actions

export default statusSlice.reducer
