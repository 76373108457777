import { createSlice } from '@reduxjs/toolkit'
import moment from 'providers/moment'

const initialState = {
  items: [],
}

const sortByArrivalTime = (a, b) => moment(a.arrival_at).diff(moment(b.arrival_at))

export const arrivalsSlice = createSlice({
  name: 'arrivals',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    updateItem: (state, action) => {
      state.items = state.items.map((item) =>
        (item.id !== action.payload.id ? item : action.payload)).sort(sortByArrivalTime)
    },
    createItem: (state, action) => {
      state.items = [action.payload, ...state.items].sort(sortByArrivalTime)
    },
    deleteItem: (state, action) => {
      state.items = state.items.filter(({ id }) => id !== action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setItems, updateItem, createItem, deleteItem,
} = arrivalsSlice.actions

export default arrivalsSlice.reducer
