import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  newCount: 0,
}

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    updateItem: (state, action) => {
      state.items = state.items.map((item) =>
        (item.id !== action.payload.id ? item : action.payload))
    },
    createItem: (state, action) => {
      state.items = [action.payload, ...state.items]
    },
    deleteItem: (state, action) => {
      state.items = state.items.filter(({ id }) => id !== action.payload)
    },
    setNewCount: (state, action) => {
      state.newCount = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setItems, updateItem, createItem, deleteItem, setNewCount,
} = announcementsSlice.actions

export default announcementsSlice.reducer
