import React, { useEffect, useCallback, useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'
import api from 'providers/api'
import moment from 'providers/moment'
import { defaultRequest } from 'providers/request'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { setItems, setNewCount } from 'features/announcements/announcementsSlice'

/* ------------------------------- Components ------------------------------- */
import Announcements from './Announcements'

/* -------------------------------------------------------------------------- */
/*                      Announcements Container Component                     */
/* -------------------------------------------------------------------------- */
const AnnouncementsContainer = () => {
  const { items, newCount } = useSelector((state) => state.announcements)
  const dispatch = useDispatch()
  const intervalRef = useRef()

  /* -------------------------------- Callbacks ------------------------------- */
  const updateNewItems = useCallback(() => {
    const newItemsCount = items.reduce((acc, item) => {
      const timeDiff = moment().diff(moment(item.createdAt), 'hours')

      return acc + (timeDiff > config.announcements.new_flag_duration ? 0 : 1)
    }, 0)
    dispatch(setNewCount(newItemsCount))
  }, [items, dispatch, setNewCount])

  const getAnnouncements = useCallback(
    () =>
      defaultRequest({
        request: () =>
          api.get(
            `${config.api.endpoints.announcements}?populate=*&sort=createdAt:desc&pagination[limit]=3`,
          ),
        onSuccess: ({ data }) => {
          dispatch(setItems(data.data))
          updateNewItems()
        },
      }),
    [dispatch, setItems],
  )

  /* ------------------------------- Use effects ------------------------------ */
  useEffect(() => {
    getAnnouncements()
    intervalRef.current = setInterval(getAnnouncements, 60000)

    return () => clearInterval(intervalRef.current)
  }, [getAnnouncements])

  useEffect(() => {
    updateNewItems()
  }, [updateNewItems])

  /* --------------------------------- Render --------------------------------- */
  return <Announcements announcements={items} newAnnouncements={newCount} />
}

export default AnnouncementsContainer
