import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { round } from 'utils/functions'

/* -------------------------------------------------------------------------- */
/*                             Pressure Component                             */
/* -------------------------------------------------------------------------- */
const Pressure = ({ pressure }) => (
  <div className="card">
    <div className="card__header mb-1">
      <div className="card__title">Ciśnienie</div>
      <div className="card__info">QNH</div>
    </div>
    <h2 className="text-right tc-primary">{round(pressure, 1)} hPa</h2>
  </div>
)

export default Pressure
