import {
  faWind as faWindSolid,
  faWindWarning,
} from '@fortawesome/pro-solid-svg-icons'
import { faWind as faWindLight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

/* -------------------------------------------------------------------------- */
/*                       Conditions Indicator Component                       */
/* -------------------------------------------------------------------------- */
const ConditionsIndicator = ({ wind_speed }) => {
  if (wind_speed > 5) {
    return (
      <div className="wind-conditions wind-conditions--strong">
        <FontAwesomeIcon icon={faWindWarning} className="mr-1" beat />
        Silny
      </div>
    )
  }

  if (wind_speed > 2) {
    return (
      <div className="wind-conditions wind-conditions--medium">
        <FontAwesomeIcon icon={faWindSolid} className="mr-1" />
        Umiarkowany
      </div>
    )
  }

  return (
    <div className="wind-conditions wind-conditions--light">
      <FontAwesomeIcon icon={faWindLight} className="mr-1" />
      Słaby
    </div>
  )
}

export default ConditionsIndicator
