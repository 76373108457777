import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'

/* -------------------------------------------------------------------------- */
/*                              Button Component                              */
/* -------------------------------------------------------------------------- */
const Button = ({ children, ...rest }) => (
  <button className={cn('btn')} {...rest}>
    {children}
  </button>
)

export default Button
