import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faGlobe,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

/* -------------------------------------------------------------------------- */
/*                              Contact Component                             */
/* -------------------------------------------------------------------------- */
const Contact = ({
  website_address,
  contact_email,
  contact_phone_number,
  instagram_profile,
}) => (
  <ul className="header__contact-list">
    <li>
      <FontAwesomeIcon
        icon={faGlobe}
        className="header__contact-icon tc-primary-4 mr-1"
      />
      <span className="header__contact-label tc-primary-2">
        {website_address}
      </span>
    </li>
    <li>
      <FontAwesomeIcon
        icon={faEnvelope}
        className="header__contact-icon tc-primary-4 mr-1"
      />
      <span className="header__contact-label tc-primary-2">
        {contact_email}
      </span>
    </li>
    <li>
      <FontAwesomeIcon
        icon={faPhone}
        className="header__contact-icon tc-primary-4 mr-1"
      />
      <span className="header__contact-label tc-primary-2">
        {contact_phone_number}
      </span>
    </li>
    <li>
      <FontAwesomeIcon
        icon={faInstagram}
        className="header__contact-icon tc-primary-4 mr-1"
      />
      <span className="header__contact-label tc-primary-2">
        @{instagram_profile}
      </span>
    </li>
  </ul>
)

export default Contact
