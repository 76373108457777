import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { round } from 'utils/functions'

/* -------------------------------------------------------------------------- */
/*                            Temperature Component                           */
/* -------------------------------------------------------------------------- */
const Temperature = ({ temperature }) => (
  <div className="card">
    <div className="card__header mb-0">
      <div className="card__title">Temperatura</div>
    </div>
    <h1 className="text-right tc-primary">{round(temperature, 1)}°C</h1>
  </div>
)

export default Temperature
