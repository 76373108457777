import config from 'config'

export const round = (number, places = 2) =>
  +`${Math.round(`${number}e+${places}`)}e-${places}`

export const convertUnit = (value, baseUnit, targetUnit) => {
  if (baseUnit === 'm/s' && targetUnit === 'kt') {
    return value * config.units.multipliers.mstokt
  }
  return value
}
