import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { round } from 'utils/functions'

/* -------------------------------------------------------------------------- */
/*                             Pressure Component                             */
/* -------------------------------------------------------------------------- */
const Humidity = ({ humidity }) => (
  <div className="card">
    <div className="card__header mb-0">
      <div className="card__title">Wilgotność</div>
    </div>
    <h2 className="text-right tc-primary">
      <span>{round(humidity, 1)}%</span>
    </h2>
  </div>
)

export default Humidity
