import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { round } from 'utils/functions'

/* -------------------------------------------------------------------------- */
/*                            Temperature Component                           */
/* -------------------------------------------------------------------------- */
const DewpointTemperature = ({ temperature, humidity }) => {
  const getDewpointTemperature = () => (
    (humidity / 100) ** (1 / 8) * (112 + 0.9 * temperature)
    + 0.1 * temperature
    - 112
  )

  /* --------------------------------- Render --------------------------------- */
  return (
    <div className="card">
      <div className="card__header mb-0">
        <div className="card__title">Temperatura punktu rosy</div>
      </div>
      <h2 className="text-right tc-primary">
        {round(getDewpointTemperature(), 1)}°C
      </h2>
    </div>
  )
}

export default DewpointTemperature
