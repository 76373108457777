import React from 'react'

/* -------------------------------------------------------------------------- */
/*                            Empty State Component                           */
/* -------------------------------------------------------------------------- */
const EmptyState = ({ children }) => (
  <div className="empty-state">
    <div className="empty-state__text">{children}</div>
  </div>
)

export default EmptyState
