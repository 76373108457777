import {
  useEffect, useRef, useState, useCallback,
} from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useSelector, useDispatch } from 'react-redux'

/* ---------------------------------- Utils --------------------------------- */
import moment from 'moment'

/* --------------------------------- Actions -------------------------------- */
import { updateTime } from 'features/time/timeSlice'

/* -------------------------------------------------------------------------- */
/*                               Time Component                               */
/* -------------------------------------------------------------------------- */
const Time = () => {
  const [formattedTime, setFormattedTime] = useState('00:00')
  const value = useSelector((state) => state.time.value)
  const dispatch = useDispatch()
  const intervalRef = useRef()

  /* -------------------------------- Callbacks ------------------------------- */
  const timerFunction = useCallback(() => {
    const now = moment()
    const timeObject = moment.unix(value)

    if (timeObject && timeObject.format('HH:mm') !== now.format('HH:mm')) {
      setFormattedTime(now.format('HH:mm'))
      dispatch(updateTime(now.unix()))
    }
  }, [])

  /* ------------------------------- Use effects ------------------------------ */
  useEffect(() => {
    timerFunction()
    intervalRef.current = setInterval(timerFunction, 1000)

    return () => clearInterval(intervalRef.current)
  }, [value])

  /* --------------------------------- Render --------------------------------- */
  return formattedTime
}

export default Time
