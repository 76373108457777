import React from 'react'

/* -------------------------------------------------------------------------- */
/*                               Badge Component                              */
/* -------------------------------------------------------------------------- */
const Badge = ({ children, color = 'default' }) => (
  <span className={`badge badge--${color}`}>
    {children}
  </span>
)

export default Badge
