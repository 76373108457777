import React from 'react'
import { useSelector } from 'react-redux'

/* ------------------------------- Components ------------------------------- */
import Contact from './Contact'

/* -------------------------------------------------------------------------- */
/*                         Contact Container Component                        */
/* -------------------------------------------------------------------------- */
const ContactContainer = () => {
  const data = useSelector((state) => state.status.data)

  if (!data) return null

  return <Contact {...data} />
}

export default ContactContainer
