import time from './time/timeSlice'
import status from './status/statusSlice'
import weather from './weather/weatherSlice'
import announcements from './announcements/announcementsSlice'
import arrivals from './arrivals/arrivalsSlice'

const features = {
  time,
  status,
  weather,
  announcements,
  arrivals,
}

export default features
