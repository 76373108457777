export const defaultRequest = async ({
  request = () => {},
  beforeRequest = () => {},
  afterRequest = () => {},
  onSuccess = () => {},
  onError = () => {},
  onForbiddenError = () => {},
  onUnprocessableError = () => {},
  onUnknownError = () => {},
  onUnexpectedError = () => {},
  onBadRequest = () => {},
}) => {
  try {
    // Call provided function
    beforeRequest()
    const response = await request()
    // Call function when exception was not thrown
    onSuccess(response)
  } catch (error) {
    if (error.response) {
      const { data } = error.response
      switch (error.response.status) {
        // Handle bad request error
        case 400:
          onBadRequest()
          break
        // Handle forbidden error
        case 403:
          onUnexpectedError()
          onForbiddenError(data)
          break
        // Handle unprocessable entity error (validation)
        case 422:
          onUnprocessableError(data)
          break
        // Handle unkown errors
        default:
          onUnknownError()
          onUnexpectedError()
      }
    } else {
      onUnexpectedError()
    }
    // Call function when exception was thrown
    onError(error)
  } finally {
    // Call finally function
    afterRequest()
  }
}

export default defaultRequest
