import { useEffect, useState } from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch } from 'react-redux'

/* ---------------------------------- Utils --------------------------------- */
import io from 'socket.io-client'
import config from 'config'

/* --------------------------------- Actions -------------------------------- */
import { setIsOpen } from 'features/status/statusSlice'
import {
  updateItem as updateAnnouncementItem,
  createItem as createAnnouncementItem,
  deleteItem as deleteAnnouncementItem,
} from 'features/announcements/announcementsSlice'
import {
  updateItem as updateArrivalItem,
  createItem as createArrivalItem,
  deleteItem as deleteArrivalItem,
} from 'features/arrivals/arrivalsSlice'

/* -------------------------------------------------------------------------- */
/*                                   Sockets                                  */
/* -------------------------------------------------------------------------- */
const Sockets = () => {
  const [socket, setSocket] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const newSocket = io(config.api.url)
    setSocket(newSocket)
    return () => newSocket.close()
  }, [setSocket])

  useEffect(() => {
    if (socket) {
      socket.on('is_open', (status) => {
        dispatch(setIsOpen(status))
      })
      socket.on('announcement_update', (data) => dispatch(updateAnnouncementItem(data)))
      socket.on('announcement_create', (data) => dispatch(createAnnouncementItem(data)))
      socket.on('announcement_delete', (data) => dispatch(deleteAnnouncementItem(data)))
      socket.on('arrival_update', (data) => dispatch(updateArrivalItem(data)))
      socket.on('arrival_create', (data) => dispatch(createArrivalItem(data)))
      socket.on('arrival_delete', (data) => dispatch(deleteArrivalItem(data)))
    }
  }, [socket])

  return <></>
}

export default Sockets
