import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'
import moment from 'providers/moment'

/* ---------------------------------- Icons --------------------------------- */
import {
  faCheck, faClock, faPhone, faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* ------------------------------- Components ------------------------------- */
import Button from 'components/elements/button'

/* --------------------------------- Custom --------------------------------- */
const getIcon = status => (status ? faCheck : faClock)

/* -------------------------------------------------------------------------- */
/*                              Arrival Component                             */
/* -------------------------------------------------------------------------- */
const Arrival = ({
  id, pilot_name, phone_number, aircraft_name, aircraft_registration, arrival_at, is_approved,
  isLoading, onChange,
}) => (
  <div className={cn('arrival', { 'arrival--pending': !is_approved })}>
    <div className="arrival__pilot-data">
      <div className="arrival__pilot-name">
        {pilot_name}
      </div>
      <div className="arrival__phone-number">
        <FontAwesomeIcon icon={faPhone} /> {phone_number}
      </div>
    </div>
    <div className="arrival__aircraft-data">
      <div className="arrival__aircraft-name">{aircraft_name}</div>
      <div className="arrival__aircraft-registration">{aircraft_registration}</div>
    </div>
    <div className="arrival__arrival-at">
      <div className="arrival__arrival-date">{moment(arrival_at).format('DD.MM HH:mm')}</div>
      <div className="arrival__arrival-countdown">{moment(arrival_at).fromNow()}</div>
    </div>
    <div className="arrival__status">
      <Button disabled={isLoading} onClick={() => onChange(id, !is_approved)}>
        <FontAwesomeIcon icon={isLoading ? faSpinnerThird : getIcon(is_approved)} className={is_approved ? 'tc-success' : 'tc-warning'} spin={isLoading} />
      </Button>
    </div>
  </div>
)

export default Arrival
