import axios from 'axios'
import config from 'config'
import store from 'store'

import { setIsOnline } from 'features/status/statusSlice'

const api = axios.create({
  baseURL: config.api.fullUrl,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${config.api.token}`
      },
    },
  },
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      store.dispatch(setIsOnline(false))
    } else {
      store.dispatch(setIsOnline(true))
    }
    return Promise.reject(error)
  },
)

export default api
