import React, { useEffect, useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import suncalc from 'suncalc'
import config from 'config'
import api from 'providers/api'
import moment from 'providers/moment'
import { defaultRequest } from 'providers/request'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { setData, setSunset } from 'features/status/statusSlice'

/* ------------------------------- Components ------------------------------- */
import Header from './Header'

/* -------------------------------------------------------------------------- */
/*                         Header Container Component                         */
/* -------------------------------------------------------------------------- */
const HeaderContainer = () => {
  const data = useSelector((state) => state.status.data)
  const intervalRef = useRef()

  const dispatch = useDispatch()

  /* -------------------------------- Functions ------------------------------- */
  const updateSunset = () => {
    const { sunset } = suncalc.getTimes(
      new Date(),
      data.airfield_latitude_number,
      data.airfield_longitude_number,
    )
    dispatch(setSunset(moment(sunset).format('HH:mm')))
  }

  /* ------------------------------- Use effects ------------------------------ */
  useEffect(() => {
    const request = async () =>
      defaultRequest({
        request: () => api.get(config.api.endpoints.status),
        onSuccess: (response) => dispatch(setData(response.data.data)),
      })
    request()
  }, [])

  useEffect(() => {
    if (data) {
      updateSunset()
      intervalRef.current = setInterval(updateSunset, config.time.sunsetInterval)
    }

    return () => clearInterval(intervalRef.current)
  }, [data])

  /* --------------------------------- Render --------------------------------- */
  return <Header {...data} />
}

export default HeaderContainer
