import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    wind_direction: 0,
    wind_speed: 0,
    temperature: 0,
    humidity: 0,
    pressure: 0,
    rain: 0,
    is_raining: false,
    created_at: null,
  },
}

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = { ...state.data, ...action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setData } = weatherSlice.actions

export default weatherSlice.reducer
