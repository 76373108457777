import React from 'react'

/* --------------------------------- Utils ---------------------------------- */
import moment from 'moment'

/* ------------------------------- Components ------------------------------- */
import Wind from './modules/wind'
import Temperature from './modules/temperature'
import Pressure from './modules/pressure'
import Humidity from './modules/humidity'
import Rain from './modules/rain'
import DewpointTemperature from './modules/dewpoint_temperature'

/* -------------------------------------------------------------------------- */
/*                              Weather Component                             */
/* -------------------------------------------------------------------------- */
const Weather = ({
  mf_wind_direction,
  wind_direction,
  wind_speed,
  last_wind_directions,
  temperature,
  pressure,
  humidity,
  rain,
  is_raining,
  created_at,
}) => (
  <div className="weather">
    <Wind {...{
      mf_wind_direction, wind_direction, wind_speed, last_wind_directions,
    }} />
    <div className="weather__info">
      <Temperature temperature={temperature} />
      <div className="mt-2">
        <Pressure pressure={pressure} />
      </div>
      <div className="mt-2">
        <Humidity humidity={humidity} />
      </div>
      <div className="mt-2">
        <Rain {...{ rain, is_raining }} />
      </div>
      <div className="mt-2">
        <DewpointTemperature {...{ temperature, humidity }} />
      </div>
    </div>
    {created_at && (
      <div className="weather__timestamp tc-gray-3">
        <strong>Ostatnia aktualizacja:</strong>{' '}
        {moment(created_at).format('DD.MM.YYYY HH:mm:ss')}
      </div>
    )}
  </div>
)

export default Weather
