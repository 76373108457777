import React, { useState } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'
import api from 'providers/api'
import { defaultRequest } from 'providers/request'

/* ------------------------------- Components ------------------------------- */
import Arrival from './Arrival'

/* -------------------------------------------------------------------------- */
/*                         Arrival Container Component                        */
/* -------------------------------------------------------------------------- */
const ArrivalContainer = (params) => {
  const [isLoading, setIsLoading] = useState(false)

  /* -------------------------------- Functions ------------------------------- */
  const handleChange = (id, state) => defaultRequest({
    beforeRequest: () => setIsLoading(true),
    afterRequest: () => setIsLoading(false),
    request: () => api.put(`${config.api.endpoints.arrivals}/${id}`, {
      data: {
        is_approved: state,
      },
    }),
  })

  return <Arrival {...params} isLoading={isLoading} onChange={handleChange} />
}

export default ArrivalContainer
