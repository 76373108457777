import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import moment from 'providers/moment'

/* -------------------------------------------------------------------------- */
/*                           Announcement Component                           */
/* -------------------------------------------------------------------------- */
const Announcement = ({
  body, author, type, createdAt,
}) => {
  const getAuthor = () => {
    if (!author) return { firstname: 'Autor', lastname: 'nieznany' }

    return author
  }

  const { firstname, lastname } = getAuthor()

  return (
    <div className={`announcement announcement--${type}`}>
      <header className="announcement__header">
        <div className="announcement__author">
          {firstname} {lastname}
        </div>
        <div className="announcement__date">{moment(createdAt).fromNow()}</div>
      </header>
      <p className="announcement__body">{body}</p>
    </div>
  )
}

export default Announcement
