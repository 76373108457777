import { faArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

/* -------------------------------------------------------------------------- */
/*                         Compact Direction Component                        */
/* -------------------------------------------------------------------------- */
const CompactDirection = ({ direction }) => (
  <FontAwesomeIcon icon={faArrowDown} style={{ transform: `rotate(${direction}deg)` }} />
)

export default CompactDirection
