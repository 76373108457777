import React from 'react'

/* ------------------------------- Components ------------------------------- */
import EmptyState from 'components/elements/empty_state'
import Arrival from './arrival'

/* -------------------------------------------------------------------------- */
/*                             Arrivals Component                             */
/* -------------------------------------------------------------------------- */
const Arrivals = ({ arrivals, onChange }) => (
  <div className="arrivals-wrapper box">
    <div className="box__header">
      <div className="box__title">Przyloty</div>
    </div>
    <div className="box__body box__body--full arrivals">
      {arrivals && arrivals.map((arrival) => (
        <Arrival key={arrival.id} {...arrival} onChange={onChange} />
      ))}
      {arrivals.length === 0 && <EmptyState>Brak przylotów</EmptyState>}
    </div>
  </div>
)

export default Arrivals
