import React from 'react'

/* ------------------------------- Components ------------------------------- */
import EmptyState from 'components/elements/empty_state'
import Badge from 'components/elements/badge'
import Announcement from './announcement/Announcement'

/* -------------------------------------------------------------------------- */
/*                           Announcements Component                          */
/* -------------------------------------------------------------------------- */
const Announcements = ({ announcements, newAnnouncements }) => (
  <div className="announcements-wrapper box">
    <div className="box__header">
      <div className="box__title">Komunikaty</div>
      <div className="box__info">
        <span className={newAnnouncements > 0 ? 'tc-warning-3' : 'tc-gray-3'}>Nowe</span> <Badge color={newAnnouncements > 0 ? 'warning' : 'default'}>{newAnnouncements}</Badge>
      </div>
    </div>
    <div className="box__body box__body--full announcements">
      {announcements.map((announcement) => (
        <Announcement key={announcement.id} {...announcement} />
      ))}
      {announcements.length === 0 && <EmptyState>Brak komunikatów</EmptyState>}
    </div>
  </div>
)

export default Announcements
