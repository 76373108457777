import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import {
  faRoadCircleCheck,
  faRoadCircleXmark,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* -------------------------------------------------------------------------- */
/*                              Status Component                              */
/* -------------------------------------------------------------------------- */
const Status = ({ isOpen, onChange, isLoading }) => {
  if (isOpen) {
    return (
      <button className="airfield-status airfield-status--open" onClick={() => onChange(false)} disabled={isLoading}>
        <FontAwesomeIcon icon={isLoading ? faSpinnerThird : faRoadCircleCheck} spin={isLoading} />
        <span className="ml-1">Lądowisko otwarte</span>
      </button>
    )
  }

  return (
    <button className="airfield-status airfield-status--closed" onClick={() => onChange(true)} disabled={isLoading}>
      <FontAwesomeIcon icon={isLoading ? faSpinnerThird : faRoadCircleXmark} spin={isLoading} />
      <span className="ml-1">Lądowisko zamknięte</span>
    </button>
  )
}

export default Status
