import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import moment from 'moment'
import { round } from 'utils/functions'

/* -------------------------------------------------------------------------- */
/*                               Metar Component                              */
/* -------------------------------------------------------------------------- */
const Metar = ({
  observed,
  icao,
  raw_text,
  barometer,
  ceiling,
  visibility,
  wind,
}) => (
  <div className="box">
    <div className="box__header">
      <div className="box__title">METAR</div>
      <div className="box__info metar__icao">
        <strong className="mr-1">Dane dla {icao}</strong>
        {moment(observed).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
    <code className="tc-gray-3">{raw_text}</code>
    <div className="metar mt-3">
      <div className="metar-box">
        <div className="metar-box__label">Ciśnienie</div>
        <div className="metar-box__value">{barometer.mb} hPa</div>
      </div>
      <div className="metar-box">
        <div className="metar-box__label">Podstawa chmur</div>
        <div className="metar-box__values">
          <div className="metar-box__value">
            {ceiling ? ceiling.code : 'CAVOK'}
          </div>
          {ceiling && (
            <>
              <div className="metar-box__value">
                {round(ceiling.meters_agl, 0)} m
              </div>
              <div className="metar-box__value metar-box__value--small">
                {round(ceiling.feet_agl, 0)} ft AGL
              </div>
            </>
          )}
        </div>
      </div>
      <div className="metar-box">
        <div className="metar-box__label">Widoczność</div>
        <div className="metar-box__values">
          <div className="metar-box__value">{visibility.meters} m</div>
          <div className="metar-box__value metar-box__value--small">
            {visibility.miles} NM
          </div>
        </div>
      </div>
      <div className="metar-box">
        <div className="metar-box__label">Wiatr</div>
        <div className="metar-box__values">
          <div className="metar-box__value">{wind.degrees}°</div>
          <div className="metar-box__value">{round(wind.speed_mps, 1)} m/s</div>
          <div className="metar-box__value metar-box__value--small">
            {round(wind.speed_kts, 1)} kt
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Metar
