import React, { useEffect, useCallback, useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'
import api from 'providers/api'
import { defaultRequest } from 'providers/request'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { setItems } from 'features/arrivals/arrivalsSlice'

/* ------------------------------- Components ------------------------------- */
import Arrivals from './Arrivals'

/* -------------------------------------------------------------------------- */
/*                        Arrivals Container Component                        */
/* -------------------------------------------------------------------------- */
const ArrivalsContainer = () => {
  const { items } = useSelector((state) => state.arrivals)
  const dispatch = useDispatch()
  const intervalRef = useRef()

  /* -------------------------------- Callbacks ------------------------------- */
  const getArrivals = useCallback(
    () =>
      defaultRequest({
        request: () =>
          api.get(`${config.api.endpoints.current_arrivals}?sort=arrival_at:asc`),
        onSuccess: ({ data }) => {
          dispatch(setItems(data.data))
        },
      }),
    [dispatch, setItems],
  )

  /* ------------------------------- Use effects ------------------------------ */
  useEffect(() => {
    getArrivals()
    intervalRef.current = setInterval(getArrivals, 60000)

    return () => clearInterval(intervalRef.current)
  }, [getArrivals])

  return <Arrivals arrivals={items} />
}

export default ArrivalsContainer
