import React from 'react'

/* ------------------------------- Components ------------------------------- */
import Header from 'components/elements/header'
import Weather from 'components/sections/weather'
import Metar from 'components/sections/metar'
import Announcements from 'components/sections/announcements'
import Arrivals from 'components/sections/arrivals'

/* --------------------------------- Assets --------------------------------- */
import enterclassLogo from 'assets/images/enterclass-logo.svg'

/* -------------------------------------------------------------------------- */
/*                           Default View Component                           */
/* -------------------------------------------------------------------------- */
const DefaultView = () => (
  <div className="wrapper">
    <Header />
    <div className="grid grid--default">
      <div>
        <Weather />
        <div className="mt-4 h-100">
          <Metar />
        </div>
      </div>
      <div>
        <Announcements />
        <Arrivals />
      </div>
    </div>
    <div className="author">
      <div className="author__label mr-1">realizacja</div>
      <a href="https://enterclass.net/">
        <img src={enterclassLogo} alt="enterclass" />
      </a>
    </div>
  </div>
)

/* --------------------------------- Export --------------------------------- */
export default DefaultView
