import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { convertUnit, round } from 'utils/functions'
import CompactDirection from './compact_direction'
import ConditionsIndicator from './conditions_indicator'

/* -------------------------------------------------------------------------- */
/*                               Wind Component                               */
/* -------------------------------------------------------------------------- */
const Wind = ({
  mf_wind_direction,
  wind_direction,
  wind_speed,
  last_wind_directions,
}) => (
  <div className="box wind">
    <div className="box__header mb-3">
      <div className="box__title">Wiatr</div>
      <div className="box__info">
        <ConditionsIndicator wind_speed={wind_speed} />
      </div>
    </div>
    <div className="wind__container">
      <div className="wind__runway"></div>
      <div
        className="wind__mf-direction-indicator"
        style={{
          transform: `translateX(-50%) translateY(-50%) rotate(${mf_wind_direction}deg)`,
        }}
      ></div>
      {mf_wind_direction !== wind_direction && <div
        className="wind__direction-indicator"
        style={{
          transform: `translateX(-50%) translateY(-50%) rotate(${wind_direction}deg)`,
        }}
      ></div>}
    </div>
    {last_wind_directions && last_wind_directions.length > 0 && <div className='wind__last-directions mt-2'>
      {last_wind_directions.map((direction, index) => (
        <CompactDirection key={index} direction={direction} />
      ))}
    </div>}
    <div className="box__header mt-1 mb-0">
      <div className="box__title">
        <h2 className="tc-primary">{round(wind_speed, 1)} m/s</h2>
        <h4 className="tc-gray-3">
          {round(convertUnit(wind_speed, 'm/s', 'kt'), 1)} kt
        </h4>
      </div>
      <div className="box__info text-center">
        <h3 className="text-right tc-gray-3">{mf_wind_direction}°</h3>
        <span className="tc-gray-3">kierunek</span>
      </div>
    </div>
  </div>
)

export default Wind
